import cloneFile from '../../functions/cloneFile'
import { uuidv4 } from '../../functions/functions'
import {
  ADD_DELIVERY_PACKAGES,
  ADD_PACKAGES,
  CLEAR_ORDER_SERVICES,
  COPY_ORDER_SERVICE,
  DELETE_DELIVERY_PACKAGES,
  DELETE_ORDER,
  DELETE_ORDER_SERVICES,
  DELETE_PACKAGES,
  SET_DELIVERY_PACKAGES,
  SET_PACKAGES,
} from '../actions/types'

const initialState = {
  packages: {},
  deliveryPackages: {},
}

const packagesReducer = function (state = initialState, action) {
  let packages = { ...state.packages }
  let deliveryPackages = { ...state.deliveryPackages }
  switch (action.type) {
    case ADD_PACKAGES:
      if (packages[action.servicesIdx]) {
        packages[action.servicesIdx].push({
          id: action.id,
          name: '',
          files: [],
        })
      } else {
        packages = {
          ...packages,
          [action.servicesIdx]: [{ id: action.id, name: '', files: [] }],
        }
      }
      return {
        ...state,
        packages,
      }
    case DELETE_PACKAGES:
      packages[action.servicesIdx].splice(action.idx, 1)

      return {
        ...state,
        packages,
      }
    case SET_PACKAGES:
      let newPackages = packages[action.servicesIdx][action.idx]

      newPackages = {
        ...newPackages,
        name: action.name ? action.name : newPackages.name,
        files: action.files ? action.files : newPackages.files,
      }
      packages[action.servicesIdx].splice(action.idx, 1, newPackages)
      return {
        ...state,
        packages,
      }
    case ADD_DELIVERY_PACKAGES:
      if (deliveryPackages[action.servicesIdx]) {
        deliveryPackages[action.servicesIdx].push({
          id: action.id,
          name: '',
          files: [],
        })
      } else {
        deliveryPackages = {
          ...deliveryPackages,
          [action.servicesIdx]: [{ id: action.id, name: '', files: [] }],
        }
      }
      return {
        ...state,
        deliveryPackages,
      }
    case DELETE_DELIVERY_PACKAGES:
      deliveryPackages[action.servicesIdx].splice(action.idx, 1)

      return {
        ...state,
        deliveryPackages,
      }
    case SET_DELIVERY_PACKAGES:
      let newDelPackages = deliveryPackages[action.servicesIdx][action.idx]

      newDelPackages = {
        ...newDelPackages,
        name: action.name ? action.name : newDelPackages.name,
        files: action.files ? action.files : newDelPackages.files,
      }
      deliveryPackages[action.servicesIdx].splice(action.idx, 1, newDelPackages)
      return {
        ...state,
        deliveryPackages,
      }
    case COPY_ORDER_SERVICE:
      if (packages[action.idx]) {
        const data = packages[action.idx].map((item) => {
          const files = []
          if (item.files && Array.isArray(item.files)) {
            for (let el of item.files) {
              files.push(cloneFile(el))
            }
          }
          return {
            ...item,
            files,
            id: uuidv4(),
          }
        })
        packages[action.servicesIdx] = data

        return {
          ...state,
          packages,
        }
      } else {
        return state
      }

    case DELETE_ORDER_SERVICES:
      delete packages[action.idx]
      packages[action.idx] = packages[action.idx + 1]
      delete packages[action.idx + 1]
      for (let key in packages) {
        if (+key > action.idx) {
          packages[key - 1] = packages[key]
          delete packages[key]
        }
      }

      return {
        ...state,
        packages,
      }
    case CLEAR_ORDER_SERVICES:
    case DELETE_ORDER:
      return {
        ...state,
        packages: {},
      }
    default:
      return state
  }
}
export default packagesReducer
